import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./genericactivation.component.css";
// import CatDog from "../../assets/generic_img.jpg";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
// import Button from '@mui/material/Button';
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import { 
  // Select,
  MenuItem,
  // TextField,
  Tooltip,
  Button
} from "@material-ui/core";
import { TextField ,Select,FormControl,InputLabel} from "@mui/material";
import axios from "axios";
import {Snackbar} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as FcIcons from "react-icons/fc";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import imageCompression from "browser-image-compression";
import setCanvasPreview from "../../setcanvasPreview.js";
import Navbar from "../header/header.component";
import { switchUrls } from "../../api/index.js";
// import Loader from "../../assets/loader.gif";
import Footer from "../footer/footer.component.jsx";
import Sidebarcontent from "../sidebar/sidebar.component.jsx";
import Alert from '@mui/material/Alert';


const baseURL = switchUrls("generic");
const basegatewayURL = switchUrls("gateway");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");
export default class GenericActivation extends React.Component {
  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.state = {
      imageUrl: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      valuable_type: "Laptop",
      valuableTypeToIdMap: {
        Laptop: 1,
        Mobile: 2,
        "Laptop Bag": 3,
        Mouse: 4,
        Camera: 5,
        Watch: 6,
        "Water Bottle": 7,
        Charger: 8,
        "Ladies Purse": 9,
        "Sports Kit Bag": 10,
        "School Bag": 11,
        Key: 12,
        Passport: 13,
        Wallet: 14,
        Others: 15,
        // Other: 13,
      },
      valuableImages: {
        1: "laptop.png",
        2: "mobile.png",
        3: "laptopbag.png",
        4: "mouse.png",
        5: "camera.png",
        6: "watch.png",
        7: "waterbottle.png",
        8: "charger.png",
        9: "ladiespurse.png",
        10: "sport-bag.png",
        11: "schoolbag.png",
        12: "otherkey.png",
        13: "passport.png",
        14: "wallet.png",
        15: "Tag8logo.png",
        // 12: "luggage.png",
      },
      valuable_desc: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      showLoader: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      show: false,
      showQR: false,
      tagNotFound: false,
      alreadyActivated: false,
      imageName: "",
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/laptop.png",
      modalShown: false,
      retryLocation: false,
     display_name: this.props.display_name || "", 
      walletDialogOpen: false,
       snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.deleteimage = this.deleteimage.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this);
    this.checkLocation = this.checkLocation.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
    this.walletDialogHandleClose = this.walletDialogHandleClose.bind(this);
  }
  deleteimage = (e) => {
    this.setState({
      imageUrl: "",
    });
  };

  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  cropsetup(e) {
    // console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }

   getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sessionStorage.setItem("latitude", latitude);
          sessionStorage.setItem("longitude", longitude);
          this.setState({
            locationData: { latitude, longitude },
          });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          this.setState({ showLocationErrorModal: true });
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };
  checkLocation = ()=>{
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
      
        if (permissionStatus.state === 'granted') {
          this.getLocation();
          // You can trigger a function here to re-fetch location data
        }
        else{
          this.setState({
            showLocationErrorModal: true,
          });
        }
      };
    });
  }

  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
   
   handleNotFound = () => {
    this.setState({ tagNotFound: false });
  };

  handleActivated = () => {
    this.setState({ alreadyActivated: false });
  };
   activateTag() {
  const { tag_number } = this.state;
  const state = {
    tag_number: tag_number.toUpperCase(),
  };
  this.setState({ showLoader: true });
  const loaderTimer = setTimeout(() => {
    this.setState({ showLoader: false });
  }, 5000);

  axios
    .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
      headers: {
        Authorization: this.props.token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;
      sessionStorage.removeItem("tag_number");

      // Check if product category starts with "Dolphin" or "Smart"
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        this.setState({ walletDialogOpen: true,showLoader: false });
        return;
      }

      const routes = {
        "QR PET": `/pet-activation/${tag_number}`,
        "QR Key": `/key-activation/${tag_number}`,
        "QR bag": `/bag-activation/${tag_number}`,
        "QR Bag": `/bag-activation/${tag_number}`,
        "E-Bag": `/bag-activation/${tag_number}`,
        "QR Medical": `/bag-activation/${tag_number}`,
        "QR Passport": `/passport-activation/${tag_number}`,
        "E-Passport": `/passport-activation/${tag_number}`,
        "QR Gadget": `/earphone-activation/${tag_number}`,
        "Generic": `/generic-activation/${tag_number}`,
        "QR Wallet": `/wallet-activation/${tag_number}`,
        "QR Human": `/human-activation/${tag_number}`,
        "QR HUMAN": `/human-activation/${tag_number}`,
        "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      this.setState({ showLoader: false });
      const responseData = error.response.data;
      console.log(responseData, "data");
      if (responseData === "Tag not found by the specified number.") {
        // this.setState({ tagNotFound: true });
        this.setState({
            tagNotFound: true,
            snackbarOpen: true,
            snackbarMessage: "Tag not found!",
            snackbarType: "warning",
          });

      } else if (responseData === "Tag is already activated.") {
        // this.setState({ show: false, alreadyActivated: true });
        this.setState({
            alreadyActivated: true,
            snackbarOpen: true,
            snackbarMessage: "Tag is already activated.",
            snackbarType: "info",
          });
      }
    });
   }
  handleCloseQRModal() {
    this.setState({ showQR: false });
  }
   walletDialogHandleClose = () => {
    this.setState({ walletDialogOpen: false });
  };
  handleQRResult(result, error) {
    if (result) {
      const activateTag = result?.text
        .split("/")
        [result?.text.split("/").length - 1].toUpperCase();
      this.setState({ activateTag, showQR: false });
    }
    if (error) {
      console.error(error.message);
    }
  }

  renderQRModal() {
    return (
      <Dialog
        open={this.state.showQR}
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseQRModal}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={this.handleQRResult}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }

   handleRetry = () => {
    this.setState({ showLocationErrorModal: false });
    this.getLocation();
  };
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    // console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob(async (blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      const imageDimension = await this.imageSize(file);
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      });
      console.log(
        "image size after compression:",
        compressedFile.size / (1024 * 1024)
      );
      this.setState({
        selectedImage: compressedFile,
        imageUrl: URL.createObjectURL(compressedFile),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
        showLoader: false,
      });
    });
  }

  componentDidMount() {
    // console.log(usertoken);
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
    this.getLocation();
    this.checkLocation();

  }

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    this.setState({
      valuable_type: selectedValuableType,
      valuable_id: selectedId,
      imageUrlvector: this.state.valuableImages[selectedId],
      // bag_imagelocation: null,
      // imageUrl: null,
      // isuploading: false,
    });
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }

  async handleChange(event) {
    // console.log("..............................");
    // console.log(".l");
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log("image original size:", sizeinmb);
      // if (sizeinmb > 5) {
      //   this.setState({
      //     message: "File size should be less than 5 MB",
      //     uploaderror: true,
      //     imagemodal: true,
      //   });
      // } else {
      // const imageDimension = await this.imageSize(event.target.files[0]);
      // const compressedFile = await imageCompression(event.target.files[0], {
      //   maxSizeMB: 3,
      //   maxWidthOrHeight:
      //     imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
      //   useWebWorker: true,
      // });

      this.setState({ imagemodal: true });
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
        isuploading: true,
        imageName: event.target.files[0].name,
      });
      // }
    } else {
      console.log("failed");
    }
  }

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }
  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  changeHandler = (e) => {
    // console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state);
    this.setState({ showLoader: true });
     const {
    tag_number,
    tag_status,
    valuable_type,
    imageUrl,
    imageUrlvector,
    display_name,
    locationData,
    selectedImage,
    imageName,
    valuableTypeToIdMap,
    valuable_desc,
  } = this.state;

  // Extract latitude and longitude from locationData
  const { latitude, longitude } = locationData;

  // Create the data object to send
  const data = {
    tag_number: this.props.match.params.tag_number,
    tag_status,
    valuable_type,
    imageUrl,
    imageUrlvector,
    latitude,
    longitude,
    valuable_id: valuableTypeToIdMap[valuable_type], // Map valuable type to its ID
    display_name,
    valuable_desc,
  };
    axios
      .post(`${baseURL}/api/generic/genericactivation`, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        // console.log(response);
        if (response.data === "Tag Already Activated") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response.data,
            showLoader: false,
          });
        } else if (response.data === "Tag not Found by the specified Number") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: response.data,
            showLoader: false,
          });
        } else if (response.data === "Generic Activated Successfully") {
          if (this.state?.selectedImage !== null) {
            const fd = new FormData();
            fd.append("image", this.state.selectedImage);
            fd.append("imageName", this.state.imageName);
            fd.append("tag_number", this.props.match.params.tag_number);
            axios
              .post(`${baseURL}/api/generic/genericimageupload`, fd)
              .then((res) => {
                // console.log(res);
                this.setState({
                  showLoader: false,
                  snackbaropen: true,
                  snackbarmsg: "Tag Activated Successfully",
                });
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              })
              .catch((error) => {
                this.setState({ showLoader: false });
                console.log(error);
              });
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Key Activation Failed",
          showLoader: true,
        });
      });
  };
  // handleShow() {
  //   window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  // }
  handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  render() {
    const {
      imageUrl,
      tag_number,
      valuable_type,
      valuable_desc,
      sidebar,
      subMenu,
      valuableTypeToIdMap,
      valuableImages,
      crop,
      uploaderror,
      message,
      imagemodal,
      show,
      setshowQR,
      tagNotFound, 
      alreadyActivated,
       locationData,
      showLocationErrorModal,
      display_name,
      walletDialogOpen,
      snackbarOpen,
      snackbarMessage,
      snackbarType,
    } = this.state;
    const formattedValuableType = valuable_type.replace(
      /([a-z])([A-Z])/g,
      "$1 $2"
    );
    // console.log(formattedValuableType, "valaube");
    const selectedId = valuableTypeToIdMap[formattedValuableType];
    const selectedImageFilename = valuableImages[selectedId];
    const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // console.log(generic_imagelocation, "locationiamage");
    // console.log(imageUrl, "purchase date");
    // console.log(imageUrlvectors, "vecto");

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
     <>
        <Navbar setName={(name) => this.setState({ display_name: name })}/>
        <div className="petActivation-main-wrapper">
          <div className="petActivation-left-wrapper">
            <Sidebarcontent
              handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound}
            />
             <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => this.setState({ showQR: true })} 
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
             <Dialog
        PaperProps={{
          style: { borderRadius: 15,padding: '1.5em',position:'relative' },
        }}
        open={walletDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
                aria-label="close"
                onClick={this.walletDialogHandleClose}
                style={{
                    position: 'absolute !impotant' ,
                    top: '10px !impotant',
                    right: '10px !impotant',
                    zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
                <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  }style={{ color: "blue" }}><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  }style={{ color: "blue" }} >
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions> 
      </Dialog>
            <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => this.setState({ snackbarOpen: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "580px", // Optionally adjust margin
          width: "100%",
        }}
      >
        <Alert
          severity={snackbarType}
          onClose={() => this.setState({ snackbarOpen: false })}
          sx={{
            height: "50px",
            fontSize: "1.2rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
          </div>
          <div className="bag-container">
            <div className="background-image">
              <div className="bag-activation-container">
                <p>
                  <strong>Generic Activation</strong>
                </p>

                <div className="bag-activation-form-container">
                  <form
                    onSubmit={this.submitHandler}
                    className="bag-activation-form"
                  >
                    <div className="editPetDetails-user-img">
                      {imageUrl?.length ? (
                        <>
                          <img
                            alt="profile"
                            src={imageUrl}
                            className="editPetDetails-image"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            alt="profile"
                            src={imageUrlvectors}
                            className="editPetDetails-image"
                          />
                        </>
                      )}

                      <div className="editPetDetails-edit-btn-wrap">
                        <label
                          htmlFor="upload"
                          className="editPetDetails-edit-button-image"
                          style={{ cursor: "pointer" }}
                        >
                          <MdIcons.MdEdit /> Change
                        </label>

                        <input
                          id="upload"
                          className="editPetDetails-input-user-img"
                          type="file"
                          onChange={this.handleChange}
                        />
                        {imageUrl?.length ? (
                          <>
                            <label
                              className="editPetDetails-delete-button-image "
                              onClick={this.deleteimage}
                            >
                              <AiIcons.AiFillDelete /> Remove
                            </label>
                          </>
                        ) : null}
                      </div>
                      <div
                        className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                        style={{ gap: "6px" }}
                      >
                        <img
                          src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                          alt="qr"
                          style={{ width: "30px", height: "30px" }}
                        />
                        <p style={{ marginTop: "15px" }}>
                          <b>
                            {tag_number && tag_number !== "-"
                              ? tag_number
                              : "N/A"}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div>
                       <div className="bagactivation-label-container">
                          <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                // style={{
                                //   marginTop: "-7px",
                                //   marginLeft: "13px",
                                // }}
                              >
                                Valuable Type
                              </InputLabel>
                              <Select
                                labelId="valuable-type-label"
                                id="valuable-type-select"
                                value={valuable_type}
                                label={"valuable Type"}
                                required
                                onChange={this.handleDropdownChange}
                                // variant="outlined"
                              >
                                <MenuItem value="">
                              <em>Select Valuable Type</em>
                            </MenuItem>
                            {Object.keys(valuableTypeToIdMap).map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                              </Select>
                            </FormControl>

                      </div>
                       <div className="bagactivation-label-container">
                        <TextField
                          type="text"
                          label="Generic Description"
                          variant="outlined"
                          // className="petactivation-input-tag"
                          name="valuable_desc"
                          value={valuable_desc}
                          onChange={this.changeHandler}
                        />
                      </div>
                      <div className="bagactivation-label-container">
                        <Tooltip
                          describeChild
                          title="This name will appear on the scan page when the tag is scanned."
                          leaveTouchDelay={3000}
                          enterTouchDelay={50}
                        >
                          <TextField
                            required
                            type="text"
                            variant="outlined"
                            name="display_name"
                            label="Display Name"
                            value={this.state.display_name}
                            onChange={this.changeHandler}
                            className="bagactivation-flex-item"
                          />
                        </Tooltip>
                      </div>

                     
                     
                      <div className="bagactivation-btn-grp">
                        <Button
                          variant="contained"
                          type="submit"
                          style={{ width: "100%" }}
                          className="submit-login bg-warning text-white mt-2"
                        >
                          SUBMIT
                        </Button>

                        <Button
                          variant="contained"
                          type="button"
                          style={{ width: "100%" }}
                          onClick={() => {
                            window.location.href =
                              "/dashboard/" + localStorage.getItem("uid");
                          }}
                          className="submit-login bg-white mt-2"
                        >
                          CANCEL
                        </Button>
                      </div>
                    </div>
                    {this.state.showLoader && (
                      <div className="loader-container">
                        <div className="loader">
                          <img
                           src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            } 
                            alt="Loading..."
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Footer />
          </div>
          <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
            <DialogTitle>Image</DialogTitle>
            <DialogContent>
              <form>
                <div style={{ margin: "1rem" }}>
                  {!uploaderror ? (
                    <>
                      <ReactCrop
                        keepSelection
                        crop={crop}
                        aspect={1}
                        minWidth={100}
                        onChange={(percentCrop) => {
                          this.setState({ crop: percentCrop });
                        }}
                      >
                        <img
                          ref={this.imageref}
                          alt="crop"
                          width="100%"
                          height="100%"
                          src={imageUrl}
                          onLoad={this.cropsetup}
                        ></img>
                      </ReactCrop>
                    </>
                  ) : (
                    <>
                      <p style={{ color: "red", textAlign: "center" }}>
                        {message}
                      </p>
                    </>
                  )}
                </div>

                <div className="crop-actionsSub">
                  <Button onClick={this.handelcancel}>Cancel</Button>
                  {!uploaderror ? (
                    <Button
                      onClick={() => {
                        this.setState({ showLoader: true });
                        this.handelimagecrop();
                      }}
                    >
                      Submit
                    </Button>
                  ) : null}
                </div>
              </form>
            </DialogContent>
            <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
          </Dialog>
          <Snackbar
            open={this.state.snackbaropen}
            autoHideDuration={3000}
            onClose={this.snackbarClose}
            message={this.state.snackbarmsg}
            action={[
              <IconButton
                key="close"
                arial-label="Close"
                color="inherit"
                onClick={this.snackbarClose}
              >
                X
              </IconButton>,
            ]}
          />
          {this.renderQRModal()}
        </div>
      </>
    );
  }
}
